import React, { useState, useEffect } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import User from '../components/dashboard/student';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'gatsby';
import { Cookies } from 'react-cookie';
import { navigate } from 'gatsby';
import { getProfile, loginCheck, switchUser } from "../state/actions/login/loginAction"
import { postMethod } from '../helper/api';

const Profiles = ({ location, props, user }) => {

  const dispatch = useDispatch();

  const [userSwitch, setUserSwitch] = useState(location?.state?.switch)
  const [profiles, setProfiles] = useState(location?.state?.profiles?location?.state?.profiles:[]);
  let cookies = new Cookies();

  useEffect(() => {
    if (user.authenticate.user && user.authenticate.jwt && userSwitch!==true) {
      if(!user.authenticate.user.firstname)
        navigate("/SignUp");
      else
        navigate("/dashboard");
    }
  }, [user.authenticate.user, user.authenticate.jwt, user.authenticate?.user?.firstname])

  useEffect(()=>{

    
    let u = user.authenticate?.user;
    //console.log(u);
    async function getProfiles(){
      let profiles = await postMethod('/auth/switchprofiles',{t: u.auth_id});
      if(userSwitch){
        setProfiles(profiles.data.profiles);
      }
    }

    if(userSwitch){
      getProfiles();
      dispatch(switchUser());
    }
    
  },[userSwitch]);

  useEffect(()=>{

    
    
    let u = user.authenticate?.user;
    async function getProfiles(){
      //console.log("called");
      let profiles = await postMethod('/auth/switchprofiles',{t: u.auth_id});
      
       setProfiles(profiles.data.profiles);

    
      
    }

    async function getUserProfiles(){
      //console.log("idTOken:::",cookies.get('idToken'));
      let profiles = await postMethod('/auth/profiles',{token: await cookies.get('idToken')});


      if(profiles.data.profiles.length>0){
        //console.log("comes here");
        //let cookies =  new Cookies();
        //cookies.set('idToken',user.user.Aa);

        if(profiles.data.profiles[0].user_id.user_role==='faculty'){
          let userAuth = { token: cookies.get('idToken'), profile: profiles.data.profiles[0].user_id.id}
          await dispatch(loginCheck(userAuth))
          navigate('/dashboard?v='+window.localStorage.getItem('appVersion'))

        }
        else{
          setProfiles(profiles.data.profiles);
        }
      }else{
        let userAuth = { token: cookies.get('idToken'), profile: null, role: window.localStorage.getItem("role")}
        dispatch(loginCheck(userAuth))
        navigate('/SignUp?v='+window.localStorage.getItem('appVersion'))
      }
    }

    if(u!==undefined && cookies.get('idToken')){
      getProfiles();
    }else{
      getUserProfiles();
    }

  },[]);

  async function addProfile() {
    //setUserSwitch(false);
    let cookies = new Cookies();
    let token = cookies.get('idToken');
    let userAuth = { token: token, profile: null }
    //dispatch(loginCheck(userAuth))
    //console.log(profiles);
    navigate("/profile", {state:{addUser:true, email: profiles[0].user_id?.email, phone: profiles[0].user_id?.phone}});
  }

  return Object.keys(profiles).length>0 && (
    <Layout title="Profiles">
      <Seo title="Profiles" />
      <div className="has-fixed-btn">
        <div className="container">
          <h5>Select a Profile</h5>
          <div className="row gx-3">
            {profiles.map((user, i) => {
              return (<div key={i} className='col-6 animated fadeInLeft' style={{ marginTop:"10px" }}>
                <div className='card-white border-radius class-list mx-sm'>
                  <User user={user} setUserSwitch={setUserSwitch}/>
                </div>
              </div>
              )
            })}
        	

            <div key={profiles.length} className='col-6 animated fadeInLeft' style={{ marginTop:"10px" }} onClick={() => addProfile()}>
              <div className='card-white border-radius class-list mx-sm'>
                <div className='teacher-card text-center card mb-0' >
                  <div className="avatar mx-auto mb-2">
                    <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />
                  </div>
                  <h6><strong>Add User</strong></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>

  )
}



export default connect(
  state => ({ user: state.loginReducer }),
  null
)(Profiles)
